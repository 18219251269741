import {IStyleParam, StyleParamType, createStylesParam, createStylesParams, wixColorParam} from '@wix/tpa-settings'
import {LIST_LOCATION_AND_DATE_FORMAT} from '@wix/wix-events-commons-statics'
import {getDefaultFont} from '../../../settings-commons/utils/style'

export type DeprecatedFonts = {
  /** @deprecated */
  listHeaderTitleFont: StyleParamType.Font
  /** @deprecated */
  listHeaderLocationFont: StyleParamType.Font
  /** @deprecated */
  listHeaderDateTimeFont: StyleParamType.Font
  /** @deprecated */
  listContentTextFont: StyleParamType.Font
  /** @deprecated */
  cardDescriptionFont: StyleParamType.Font
  /** @deprecated */
  listGeneralTitleFont: StyleParamType.Font
  /** @deprecated */
  listFullButtonFont: StyleParamType.Font
  /** @deprecated */
  listHollowButtonFont: StyleParamType.Font
}

export type DeprecatedColors = {
  /** @deprecated */
  listHeaderTitleColor: StyleParamType.Color
  /** @deprecated */
  listContentTitleColor: StyleParamType.Color
  /** @deprecated */
  listHeaderDateTimeColor: StyleParamType.Color
  /** @deprecated */
  listContentTextColor: StyleParamType.Color
  /** @deprecated */
  cardBackgroundColor: StyleParamType.Color
  /** @deprecated */
  cardsBackgroundColor: StyleParamType.Color
  /** @deprecated */
  listBackgroundColor: StyleParamType.Color
  /** @deprecated */
  cardBorderColor: StyleParamType.Color
  /** @deprecated */
  listDividerColor: StyleParamType.Color
  /** @deprecated */
  listHeaderLocationColor: StyleParamType.Color
  /** @deprecated */
  listBorderColor: StyleParamType.Color
}

export type DeprecatedBooleans = {
  /** @deprecated */
  listShowDate: StyleParamType.Boolean
  /** @deprecated */
  listShowVenueName: StyleParamType.Boolean
  /** @deprecated */
  listShowFullDate: StyleParamType.Boolean
  /** @deprecated */
  listShowLocation: StyleParamType.Boolean
}

export type DeprecatedNumbers = {
  /** @deprecated */
  cardLocationAndDateFontSize: StyleParamType.Number
  /** @deprecated */
  cardDescriptionFontSize: StyleParamType.Number
  /** @deprecated */
  listHeaderTitleFontSizeCompactMobile: StyleParamType.Number
  /** @deprecated */
  listDateFontSizeCompactMobile: StyleParamType.Number
  /** @deprecated */
  listLocationFontSizeCompactMobile: StyleParamType.Number
  /** @deprecated */
  listHeaderTitleFontSizeMobile: StyleParamType.Number
  /** @deprecated */
  listDateFontSizeMobile: StyleParamType.Number
  /** @deprecated */
  listContentTextFontSizeMobile: StyleParamType.Number
  /** @deprecated */
  listGeneralTitleFontSizeMobile: StyleParamType.Number
  /** @deprecated */
  listButtonFontSizeMobile: StyleParamType.Number
  /** @deprecated */
  listButtonFontSizeCompactMobile: StyleParamType.Number
  /** @deprecated */
  listLocationAndDateFormat: StyleParamType.Number
  /** @deprecated */
  cardLocationAndDateFormat: StyleParamType.Number
  /** @deprecated */
  cardMargins: StyleParamType.Number
  /** @deprecated */
  sideBySideLayoutMargins: StyleParamType.Number
  /** @deprecated */
  listStripWidth: StyleParamType.Number
  /** @deprecated */
  cardBorderWidth: StyleParamType.Number
  /** @deprecated */
  listDividerWidth: StyleParamType.Number
  /** @deprecated */
  listBorderWidth: StyleParamType.Number
}

export type DeprecatedStyles = DeprecatedFonts & DeprecatedBooleans & DeprecatedColors & DeprecatedNumbers

export type GeneratedDeprecatedStyles = {
  [P in keyof DeprecatedStyles]: IStyleParam<DeprecatedStyles[P]>
}

export const createDeprecatedStyles = (): GeneratedDeprecatedStyles => {
  const listHeaderTitleFont = createStylesParam('listHeaderTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 26,
    }),
  })

  const listHeaderTitleColor = createStylesParam('listHeaderTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  })

  const styles = createStylesParams<DeprecatedStyles>({
    listHeaderTitleFont,
    listHeaderTitleColor,
    listHeaderLocationFont: {
      type: StyleParamType.Font,
      getDefaultValue: getDefaultFont('Body-M', {
        size: 16,
      }),
    },
    cardLocationAndDateFontSize: {
      type: StyleParamType.Number,
      getDefaultValue: () => 16,
    },
    listContentTitleColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    listHeaderDateTimeFont: {
      type: StyleParamType.Font,
      getDefaultValue: params => ({
        ...params.getStyleParamValue(listHeaderTitleFont),
        size: 16,
      }),
    },
    listHeaderDateTimeColor: {
      type: StyleParamType.Color,
      getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(listHeaderTitleColor),
    },
    listContentTextColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    },
    listContentTextFont: {
      type: StyleParamType.Font,
      getDefaultValue: getDefaultFont('Body-M', {
        size: 14,
      }),
    },
    cardDescriptionFontSize: {
      type: StyleParamType.Number,
      getDefaultValue: () => 15,
    },
    listHeaderTitleFontSizeCompactMobile: {
      type: StyleParamType.Number,
      getDefaultValue: () => 16,
    },
    listDateFontSizeCompactMobile: {
      type: StyleParamType.Number,
      getDefaultValue: () => 14,
    },
    listLocationFontSizeCompactMobile: {
      type: StyleParamType.Number,
      getDefaultValue: () => 14,
    },
    listHeaderTitleFontSizeMobile: {
      type: StyleParamType.Number,
      getDefaultValue: () => 20,
    },
    listDateFontSizeMobile: {
      type: StyleParamType.Number,
      getDefaultValue: () => 16,
    },
    listContentTextFontSizeMobile: {
      type: StyleParamType.Number,
      getDefaultValue: () => 16,
    },
    cardDescriptionFont: {
      type: StyleParamType.Font,
      getDefaultValue: getDefaultFont('Body-M', {
        size: 16,
      }),
    },
    listGeneralTitleFontSizeMobile: {
      type: StyleParamType.Number,
      getDefaultValue() {
        return 28
      },
    },
    listGeneralTitleFont: {
      type: StyleParamType.Font,
      getDefaultValue: params =>
        getDefaultFont('Page-title', {
          size: 36,
        })(params),
    },
    listHeaderLocationColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    },
    listFullButtonFont: {
      type: StyleParamType.Font,
      getDefaultValue: getDefaultFont('Body-M', {
        size: 16,
      }),
    },
    listHollowButtonFont: {
      type: StyleParamType.Font,
      getDefaultValue: getDefaultFont('Body-M', {
        size: 16,
      }),
    },
    listButtonFontSizeMobile: {
      type: StyleParamType.Number,
      getDefaultValue() {
        return 16
      },
    },
    listButtonFontSizeCompactMobile: {
      type: StyleParamType.Number,
      getDefaultValue() {
        return 14
      },
    },
    listShowDate: {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
    listShowVenueName: {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
    listShowFullDate: {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
    listShowLocation: {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
    listLocationAndDateFormat: {
      type: StyleParamType.Number,
      getDefaultValue: () => LIST_LOCATION_AND_DATE_FORMAT.COMPACT,
    },
    cardLocationAndDateFormat: {
      type: StyleParamType.Number,
      getDefaultValue: () => LIST_LOCATION_AND_DATE_FORMAT.COMPACT,
    },
    cardMargins: {
      type: StyleParamType.Number,
      getDefaultValue: () => 20,
    },
    sideBySideLayoutMargins: {
      type: StyleParamType.Number,
      getDefaultValue: () => 30,
    },
    listStripWidth: {
      type: StyleParamType.Number,
      getDefaultValue: () => 20,
    },
    cardBackgroundColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1', 1),
    },
    cardsBackgroundColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1', 0),
    },
    listBackgroundColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1', 1),
    },
    cardBorderColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-2', 1),
    },
    listDividerColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-2', 1),
    },
    cardBorderWidth: {
      type: StyleParamType.Number,
      getDefaultValue: () => 1,
    },
    listDividerWidth: {
      type: StyleParamType.Number,
      getDefaultValue: () => 1,
    },
    listBorderColor: {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-2', 1),
    },
    listBorderWidth: {
      type: StyleParamType.Number,
      getDefaultValue: () => 0,
    },
  })
  return styles
}

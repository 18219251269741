import {isDarkColor} from '@wix/panda-js-utils'
import {
  ALIGNMENT,
  BUTTONS_STYLE,
  IMAGE_RATIO,
  IMAGE_SCALING,
  LIST_ALIGNMENT,
  LIST_IMAGE_LAYOUT,
  LIST_IMAGE_SIZE,
  LIST_LAYOUT,
  LIST_LAYOUT_TYPE,
  ON_IMAGE_CARD_HEIGHT_BEHAVIOR,
  VERTICAL_ALIGNMENT,
} from '@wix/wix-events-commons-statics'
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor'
import {remapColor, remapFont} from '../../commons/custom-css-utils'
import {degreesToRadians} from '../../commons/utils/math'
import {StyleSelectors} from './style-selectors'
import stylesParams, {WidgetStyleParams} from './stylesParams'

export const customCssVars: CustomCssVarsFn<WidgetStyleParams> = ({styleParams, isMobile: mobile, isRTL: RTL}) => {
  const styles = {
    get: param => {
      return (
        styleParams.booleans[param.key] ??
        styleParams.numbers[param.key] ??
        styleParams.colors[param.key] ??
        styleParams.fonts[param.key]
      )
    },
  }

  const get = param => {
    const key = param.key
    const value = styles.get(param)

    if (mobile) {
      const mobileKey = key + 'Mobile'
      const deprecatedMobileValue = styles.get(mobileKey)

      return value ?? deprecatedMobileValue
    }

    return value
  }

  const styleSelectors = StyleSelectors(get, mobile)

  const getListLayout = () => {
    const listLayout = get(stylesParams.listLayout)
    return listLayout === LIST_LAYOUT.GRID ? LIST_LAYOUT.NEW_GRID : listLayout
  }

  const isHollowButton = (buttonStyle: BUTTONS_STYLE) =>
    [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  const isRoundedButton = (buttonStyle: BUTTONS_STYLE) =>
    [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  const isSingleHollowButton = () => isHollowButton(get(stylesParams.buttonsStyle))

  const isSingleRoundedButton = () => isRoundedButton(get(stylesParams.buttonsStyle))

  const isListHollowButton = () => isHollowButton(get(stylesParams.listButtonStyle))

  const isListRoundedButton = () => isRoundedButton(get(stylesParams.listButtonStyle))

  const isCalendarLayout = () => styleSelectors.isMultiEventWidget() && getListLayout() === LIST_LAYOUT.CALENDAR

  const isTodayButtonHollow = () => isHollowButton(get(stylesParams.todayButtonStyle))

  const isTodayButtonRounded = () => isRoundedButton(get(stylesParams.todayButtonStyle))

  const isCardsLayout = () =>
    (styleSelectors.isMultiEventWidget() || styleSelectors.isFeaturedWidget()) &&
    (getListLayout() === LIST_LAYOUT.NEW_GRID || getListLayout() === LIST_LAYOUT.GRID)

  const isListStripsLayout = () =>
    (styleSelectors.isMultiEventWidget() || styleSelectors.isFeaturedWidget()) &&
    getListLayout() === LIST_LAYOUT.LIST &&
    get(stylesParams.listLayoutType) === LIST_LAYOUT_TYPE.STRIPS

  const isSideBySideLayout = () => getListLayout() === LIST_LAYOUT.SIDE_BY_SIDE

  const isListImageLayoutRight = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.RIGHT
  const isListImageLayoutLeft = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.LEFT

  const getSideBySideEvenImageDirection = () => (isListImageLayoutLeft() ? 'row' : 'row-reverse')

  const getSideBySideOddImageDirection = () => (isListImageLayoutRight() ? 'row-reverse' : 'row')

  const isMultiFitImage = () => get(stylesParams.multiImageScaling) === IMAGE_SCALING.FIT

  const isSingleImageEnabled = () => get(stylesParams.showImage)

  const isSingleFitImage = () => get(stylesParams.imageScaling) === IMAGE_SCALING.FIT

  const getMultiImageRatio = () => {
    if (get(stylesParams.multiImageRatio)) {
      return get(stylesParams.multiImageRatio)
    }

    if (isCardsLayout() && get(stylesParams.imageRatio)) {
      return get(stylesParams.imageRatio)
    }

    return getDefaultMultiImageRatio()
  }

  const isMultiImageSquare = () => getMultiImageRatio() === IMAGE_RATIO['1:1']

  const isListImageSmall = () => get(stylesParams.listImageSize) === LIST_IMAGE_SIZE.SMALL

  const isListImageMedium = () => get(stylesParams.listImageSize) === LIST_IMAGE_SIZE.MEDIUM

  const getSquareImageFixedWidth = () => (isListImageSmall() ? 80 : isListImageMedium() ? 140 : 200)

  const getListImageFixedWidth = () =>
    isMultiImageSquare() ? getSquareImageFixedWidth() : isListImageSmall() ? 142 : isListImageMedium() ? 249 : 355

  const getDefaultMultiImageRatio = () => {
    if (isCalendarLayout() || (mobile && !isCardsLayout())) {
      return IMAGE_RATIO['16:9']
    }

    return IMAGE_RATIO['1:1']
  }

  const getListShortDateLocationAlignment = () => {
    const alignment = get(stylesParams.listAlignment)

    switch (alignment) {
      case LIST_ALIGNMENT.LEFT:
        return RTL ? 'flex-end' : 'flex-start'
      case LIST_ALIGNMENT.CENTER:
        return 'center'
      case LIST_ALIGNMENT.RIGHT:
        return RTL ? 'flex-start' : 'flex-end'
    }
  }

  const getListTextAlignment = () => {
    const alignment = get(stylesParams.listAlignment)

    switch (alignment) {
      case LIST_ALIGNMENT.LEFT:
        return 'left'
      case LIST_ALIGNMENT.CENTER:
        return 'center'
      case LIST_ALIGNMENT.RIGHT:
        return 'right'
    }
  }

  const isListTextAlignmentLeft = () => get(stylesParams.listAlignment) === LIST_ALIGNMENT.LEFT
  const isListTextAlignmentRight = () => get(stylesParams.listAlignment) === LIST_ALIGNMENT.RIGHT

  const getSingleButtonColor = () => (isSingleHollowButton() ? 'hollowButtonsFontColor' : 'fullButtonsFontColor')

  const getSingleOnImageButtonColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? 'onImageHollowButtonsFontColor' : 'onImageFullButtonsFontColor'
    } else {
      return getSingleButtonColor()
    }
  }

  const getSingleButtonBackgroundColor = () => (isSingleHollowButton() ? null : 'buttonsBackgroundColor')

  const getSingleOnImageButtonBackgroundColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? null : 'onImageButtonsBackgroundColor'
    } else {
      return getSingleButtonBackgroundColor()
    }
  }

  const getSingleButtonBorderColor = () => (isSingleHollowButton() ? 'buttonsBorderColor' : null)

  const getSingleOnImageButtonBorderColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? 'onImageButtonsBorderColor' : null
    } else {
      return getSingleButtonBorderColor()
    }
  }

  const getCalendarEventNameFontSize = () =>
    Number(get(stylesParams.calendarCellEventNameFont).size?.replace('px', '')) || 14

  const getEventBoxShadow = () => {
    if (!styles.get(stylesParams.eventBoxShadowVisible)) {
      return 'none'
    }
    const round = (n: number) => Math.round(n * 10) / 10

    const distance = styles.get(stylesParams.eventBoxShadowDistance)
    const angle = degreesToRadians(styles.get(stylesParams.eventBoxShadowAngle))

    const yOffset = round(Math.cos(angle) * distance * -1)
    const xOffset = round(Math.sin(angle) * distance)

    return `${xOffset}px ${yOffset}px calc(var(--eventBoxShadowBlur) * 1px) rgb(var(--eventBoxShadowColor))`
  }

  const getListVerticalFlexAlignment = () => {
    const verticalAlignment = styles.get(stylesParams.listTextVerticalAlignment)

    if (verticalAlignment === VERTICAL_ALIGNMENT.TOP) {
      return 'start'
    }
    if (verticalAlignment === VERTICAL_ALIGNMENT.CENTER) {
      return 'center'
    }

    return 'end'
  }

  const getListVerticalAlignmentMargins = () => {
    const verticalAlignment = styles.get(stylesParams.listTextVerticalAlignment)

    if (verticalAlignment === VERTICAL_ALIGNMENT.TOP) {
      return '0 0 auto 0'
    }
    if (verticalAlignment === VERTICAL_ALIGNMENT.CENTER) {
      return 'auto 0'
    }

    return 'auto 0 0 0'
  }

  const getPromotionalBadgeHorizontalAlign = () => {
    const alignment = styles.get(stylesParams.promotionalBadgeHorizontalAlignment)

    return alignment === ALIGNMENT.LEFT ? 'left' : alignment === ALIGNMENT.CENTER ? 'center' : 'right'
  }

  return {
    CARD_MAX_WIDTH: '700',
    CARD_MIN_WIDTH: '200',
    listMainTitleVisibility: styleSelectors.isListMainTitleEnabled() ? 'block' : 'none',
    listImageVisibility: styleSelectors.isListImageEnabled() ? 'block' : 'none',
    listDescriptionVisibility: styleSelectors.isListDescriptionEnabled() ? 'block' : 'none',
    listCountdownVisibility: styleSelectors.isListCountdownEnabled() ? 'block' : 'none',
    listSocialBarVisibility: styleSelectors.isListSocialShareEnabled() ? 'block' : 'none',
    listMembersVisibility: styleSelectors.isListMembersEnabled() ? 'block' : 'none',
    listSocialBarVisibilityFlex: styleSelectors.isListSocialShareEnabled() ? 'flex' : 'none',
    listShortDateLocationAlignment: getListShortDateLocationAlignment(),
    listTextAlignment: getListTextAlignment(),
    listImageAspectRatio: isMultiImageSquare() ? '100' : '56.25',
    listImageFixedWidth: getListImageFixedWidth(),
    listImageFixedHeight: getSquareImageFixedWidth(),
    listAdditionalInfoVisibility: styleSelectors.isListAdditionalComponentsVisible() ? 'block' : 'none',
    listAdditionalInfoVisibilityArrow: styleSelectors.isListAdditionalComponentsVisible() ? 'inline-flex' : 'none',
    isListTextAlignmentLeft: isListTextAlignmentLeft() ? '1' : '0',
    isListTextAlignmentRight: isListTextAlignmentRight() ? '1' : '0',
    sideBySideImageDirectionEven: getSideBySideEvenImageDirection(),
    sideBySideImageDirectionOdd: getSideBySideOddImageDirection(),
    sideBySideMinimumItemWidth: styleSelectors.isListImageEnabled() ? '480px' : '320px',
    listLayoutTitleLocationVisibility: styleSelectors.isListLocationVisible() ? 'block' : 'none',
    multiContainImageVisibility: isMultiFitImage() ? 'flex' : 'none',
    multiCoverImageVisibility: isMultiFitImage() ? 'none' : 'flex',
    singleContainImageVisibility: isSingleFitImage() ? 'flex' : 'none',
    singleCoverImageVisibility: isSingleFitImage() ? 'none' : 'flex',
    calendarEventTitleLineClamp: getCalendarEventNameFontSize() < 16 ? '2' : '1',
    ribbonBottomMargin: styleSelectors.isListCountdownEnabled() ? '12' : '20',
    mobileCompactRibbonBottomMargin: styleSelectors.isListCountdownEnabled() ? '8' : '12',
    sideBySideMaxItemHeight: styleSelectors.isFixedItemHeight() ? 'none' : '500px',
    sideBySideMinItemHeight: styleSelectors.isFixedItemHeight() ? '0px' : '500px',
    onImageMaxHeight: styleSelectors.isFixedItemHeight() ? 'none' : '528px',
    onImageMinHeight: styleSelectors.isFixedItemHeight() ? 'none' : '232px',
    fixedItemHeight: styleSelectors.isFixedItemHeight() && !mobile ? 'calc(var(--itemFixedHeight) * 1px)' : 'auto',

    // SINGLE LAYOUT button
    ...remapFont('singleButtonFont', isSingleHollowButton() ? 'hollowButtonsFont' : 'fullButtonsFont'),
    ...remapColor('singleButtonColor', getSingleButtonColor()),
    ...remapColor('singleOnImageButtonColor', getSingleOnImageButtonColor()),
    ...remapColor('singleButtonBackgroundColor', getSingleButtonBackgroundColor()),
    ...remapColor('singleOnImageButtonBackgroundColor', getSingleOnImageButtonBackgroundColor()),
    ...remapColor('singleButtonBorderColor', getSingleButtonBorderColor()),
    ...remapColor('singleOnImageButtonBorderColor', getSingleOnImageButtonBorderColor()),
    singleButtonBorderWidth: isSingleHollowButton() ? 'var(--buttonsBorderWidth)' : '0',
    singleButtonBorderRadius: isSingleRoundedButton()
      ? 'var(--roundedButtonsBorderRadius)'
      : 'var(--buttonsBorderRadius)',

    // LIST layouts button
    ...remapFont('multiButtonFont', isListHollowButton() ? 'listHollowButtonFontV2' : 'listFullButtonFontV2'),
    ...remapColor('multiButtonColor', isListHollowButton() ? 'listHollowButtonFontColor' : 'listFullButtonFontColor'),
    ...remapColor('multiButtonBackgroundColor', isListHollowButton() ? null : 'listButtonBackgroundColor'),
    ...remapColor('multiButtonBorderColor', isListHollowButton() ? 'listButtonBorderColor' : null),
    multiButtonBorderWidth: isListHollowButton() ? 'var(--listButtonBorderWidth)' : '0',
    multiButtonBorderRadius: isListRoundedButton()
      ? 'var(--listRoundedButtonBorderRadius)'
      : 'var(--listButtonBorderRadius)',
    ...remapColor('listLoadMoreContainerBackgroundColor', isListStripsLayout() ? null : 'listItemBackgroundColor'),
    ...remapColor(
      'listLoadMoreContainerBorderColor',
      (isCardsLayout() || isSideBySideLayout()) && !styleSelectors.isMobileCompactLayout() ? 'cardBorderColor' : null,
    ),
    listLoadMoreContainerBorderWidth:
      (isCardsLayout() || isSideBySideLayout()) && !styleSelectors.isMobileCompactLayout()
        ? 'var(--listItemBorderAndDividerWidth)'
        : '0',

    // CALENDAR TODAY button
    ...remapFont('todayButtonResolvedFont', isTodayButtonHollow() ? 'todayHollowButtonFont' : 'todayButtonFont'),
    ...remapColor('todayButtonResolvedColor', isTodayButtonHollow() ? 'todayHollowButtonColor' : 'todayButtonColor'),
    ...remapColor('todayButtonResolvedBackgroundColor', isTodayButtonHollow() ? null : 'todayButtonBackgroundColor'),
    ...remapColor('todayButtonResolvedBorderColor', isTodayButtonHollow() ? 'todayButtonBorderColor' : null),
    todayButtonResolvedBorderWidth: isTodayButtonHollow() ? 'var(--todayButtonBorderWidth)' : '0',
    todayButtonResolvedBorderRadius: isTodayButtonRounded()
      ? 'var(--todayRoundedButtonBorderRadius)'
      : 'var(--todayButtonBorderRadius)',

    // List date and location visibility
    listDateVisibility: styleSelectors.isListDateVisible() ? 'block' : 'none',
    listLocationVisibility: styleSelectors.isListLocationVisible() ? 'block' : 'none',

    listShortDateLocationDividerVisibility:
      !styleSelectors.isListDateVisible() || !styleSelectors.isListLocationVisible() ? 'none' : 'initial',

    listShortDateLocationVisibility: styleSelectors.isListShortDateLocationVisible() ? 'block' : 'none',
    listFullDateLocationVisibility: styleSelectors.isListFullDateLocationVisible() ? 'block' : 'none',

    listExtraInfoDateVisibility: styleSelectors.isListExtraInfoDateVisible() ? 'block' : 'none',
    listExtraInfoLocationVisibility: styleSelectors.isListExtraInfoLocationVisible() ? 'block' : 'none',

    listShortDateVisibility: styleSelectors.isListShortDateVisible() ? 'block' : 'none',
    listFullDateVisibility: styleSelectors.isListFullDateVisible() ? 'block' : 'none',

    listShortLocationVisibility: styleSelectors.isListShortLocationVisible() ? 'block' : 'none',
    listFullLocationVisibility: styleSelectors.isListFullLocationVisible() ? 'block' : 'none',
    listExtraInfoDateLocationVisibility: styleSelectors.isListExtraInfoDateOrLocationVisible() ? 'block' : 'none',

    cardColorScheme: isDarkColor(get(stylesParams.listItemBackgroundColor)) ? 'dark' : 'light',
    onImageColorScheme: isDarkColor(get(stylesParams.eventTitleColorV2)) ? 'light' : 'dark',
    eventBoxShadow: getEventBoxShadow(),
    listVerticalFlexAlignment: getListVerticalFlexAlignment(),
    listVerticalAlignmentMargins: getListVerticalAlignmentMargins(),
    onImageCardRows:
      get(stylesParams.onImageCardHeightBehavior) === ON_IMAGE_CARD_HEIGHT_BEHAVIOR.FIXED ? '1fr' : 'initial',
    promotionalBadgeVerticalAlign:
      styles.get(stylesParams.promotionalBadgeVerticalAlignment) === VERTICAL_ALIGNMENT.TOP ? 'start' : 'end',
    promotionalBadgeHorizontalAlign: getPromotionalBadgeHorizontalAlign(),
  }
}

import {IStylesGetParam} from '@wix/tpa-settings/dist/src/contexts/StylesContext/StylesContext.types'
import {
  ITEM_HEIGHT_TYPE,
  LIST_LOCATION_AND_DATE_FORMAT,
  MOBILE_LIST_LAYOUT_TYPE,
  MOBILE_WIDGET_LAYOUT,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import stylesParams from './stylesParams'

export const StyleSelectors = (get: IStylesGetParam, mobile: boolean) => {
  const getWidgetType = () => get(stylesParams.widgetType)

  const getMobileWidgetLayout = () => get(stylesParams.mobileWidgetLayout)

  const getMobileListLayoutType = () => get(stylesParams.mobileListLayoutType)

  const isMultiEventWidget = () => getWidgetType() === WIDGET_TYPE.LIST

  const isFeaturedWidget = () => getWidgetType() === WIDGET_TYPE.FEATURED

  const isMobileCompactLayout = () =>
    (isMultiEventWidget() || isFeaturedWidget()) &&
    getMobileWidgetLayout() === MOBILE_WIDGET_LAYOUT.LIST &&
    getMobileListLayoutType() !== MOBILE_LIST_LAYOUT_TYPE.FULL

  const isListAdditionalInfoEnabled = () => get(stylesParams.listShowAdditionalInfo)

  const isListDescriptionEnabled = () => get(stylesParams.listShowDescription)

  const isListCountdownEnabled = () => get(stylesParams.listShowCountdown)

  const isListSocialShareEnabled = () => get(stylesParams.listShowSocialShare)

  const isListMembersEnabled = () => get(stylesParams.listShowMembers)

  const isListAdditionalComponentsVisible = () => {
    if (mobile && isMobileCompactLayout()) {
      return false
    }
    return (
      isListAdditionalInfoEnabled() &&
      (isListExtraInfoDateVisible() ||
        isListExtraInfoLocationVisible() ||
        isListDescriptionEnabled() ||
        isListSocialShareEnabled())
    )
  }

  const isListImageEnabled = () => get(stylesParams.listShowImage)

  const isListMainTitleEnabled = () => get(stylesParams.listShowMainTitle)

  const isListDateVisible = () => get(stylesParams.listShowDateV2)

  const isListLocationVisible = () => get(stylesParams.listShowLocationV2)

  const isListExtraInfoDateVisible = () => get(stylesParams.listExtraInfoShowDate)

  const isListExtraInfoLocationVisible = () => get(stylesParams.listExtraInfoShowLocation)

  const isListDateAndLocationContainerVisible = () => isListDateVisible() || isListLocationVisible()

  const isListDateLocationFormatShort = () =>
    get(stylesParams.listLocationAndDateFormatV2) === LIST_LOCATION_AND_DATE_FORMAT.COMPACT

  const isListDateLocationFormatFull = () =>
    get(stylesParams.listLocationAndDateFormatV2) === LIST_LOCATION_AND_DATE_FORMAT.FULL

  const isListShortDateLocationVisible = () =>
    isListDateAndLocationContainerVisible() && isListDateLocationFormatShort()
  const isListFullDateLocationVisible = () => isListDateAndLocationContainerVisible() && isListDateLocationFormatFull()
  const isListExtraInfoDateOrLocationVisible = () => isListExtraInfoDateVisible() || isListExtraInfoLocationVisible()

  const isListShortDateVisible = () => isListDateVisible() && isListDateLocationFormatShort()
  const isListFullDateVisible = () => isListDateVisible() && isListDateLocationFormatFull()

  const isListShortLocationVisible = () => isListLocationVisible() && isListDateLocationFormatShort()
  const isListFullLocationVisible = () => isListLocationVisible() && isListDateLocationFormatFull()

  const isFixedItemHeight = () => get(stylesParams.itemHeightType) === ITEM_HEIGHT_TYPE.FIXED

  return {
    isFeaturedWidget,
    isMultiEventWidget,
    isMobileCompactLayout,
    isListAdditionalInfoEnabled,
    isListDescriptionEnabled,
    isListCountdownEnabled,
    isListSocialShareEnabled,
    isListMembersEnabled,
    isListAdditionalComponentsVisible,
    isListImageEnabled,
    isListMainTitleEnabled,
    isFixedItemHeight,
    isListDateVisible,
    isListLocationVisible,
    isListDateAndLocationContainerVisible,
    isListShortDateLocationVisible,
    isListFullDateLocationVisible,
    isListExtraInfoDateVisible,
    isListExtraInfoLocationVisible,
    isListShortDateVisible,
    isListFullDateVisible,
    isListShortLocationVisible,
    isListFullLocationVisible,
    isListExtraInfoDateOrLocationVisible,
  }
}

import {IStyleParam, IWixStyleFont, StyleParamType} from '@wix/tpa-settings'
import {LIST_LAYOUT, MOBILE_LIST_LAYOUT_TYPE} from '@wix/wix-events-commons-statics'
import {GeneratedDeprecatedStyles} from '../../../stylesParams/deprecated-styles'
import {ADDITIONAL_MOBILE_LIST_LAYOUT, WidgetText} from './constants'

export interface FontConfig {
  font?: IStyleParam<StyleParamType.Font>
  color?: IStyleParam<StyleParamType.Color>
  size?: IStyleParam<StyleParamType.Number>
  hoverFont?: IStyleParam<StyleParamType.Font>
  hoverColor?: IStyleParam<StyleParamType.Color>
  hoverSize?: IStyleParam<StyleParamType.Number>
  staticFontSize?: number
  hoverStaticFontSize?: number
  style?: Partial<IWixStyleFont['style']>
}

type WidgetFontMap = Partial<Record<WidgetText, FontConfig>>

export interface StudioParamsMap {
  desktop: Record<Exclude<LIST_LAYOUT, LIST_LAYOUT.ON_IMAGE>, WidgetFontMap>
  mobile: Record<
    Exclude<MOBILE_LIST_LAYOUT_TYPE, MOBILE_LIST_LAYOUT_TYPE.CARDS> | ADDITIONAL_MOBILE_LIST_LAYOUT,
    WidgetFontMap
  >
}

const LINK_STATIC_FONT_SIZE = 16
const LIST_EXPANDED_STATIC_FONT_SIZE = 16

const getCalendarMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
  },
})

const getCardsMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
  },
  [WidgetText.TITLE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
  },
  [WidgetText.DATE]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderTitleColor,
    hoverFont: deprecatedStyles.listHeaderLocationFont,
    hoverColor: deprecatedStyles.listHeaderTitleColor,
    hoverSize: deprecatedStyles.cardLocationAndDateFontSize,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: deprecatedStyles.listHeaderLocationFont,
    hoverColor: deprecatedStyles.listHeaderTitleColor,
    hoverSize: deprecatedStyles.cardDescriptionFontSize,
  },
  [WidgetText.LOCATION]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderTitleColor,
    hoverFont: deprecatedStyles.listHeaderLocationFont,
    hoverColor: deprecatedStyles.listHeaderTitleColor,
    hoverSize: deprecatedStyles.cardLocationAndDateFontSize,
  },
  [WidgetText.LINK]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderTitleColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getListMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
  },
  [WidgetText.TITLE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    hoverFont: deprecatedStyles.listHeaderTitleFont,
    hoverColor: deprecatedStyles.listContentTitleColor,
  },
  [WidgetText.DATE]: {
    font: deprecatedStyles.listHeaderDateTimeFont,
    color: deprecatedStyles.listHeaderDateTimeColor,
    hoverFont: deprecatedStyles.listContentTextFont,
    hoverColor: deprecatedStyles.listContentTextColor,
    hoverStaticFontSize: LIST_EXPANDED_STATIC_FONT_SIZE,
  },
  [WidgetText.LOCATION]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderLocationColor,
    hoverFont: deprecatedStyles.listContentTextFont,
    hoverColor: deprecatedStyles.listContentTextColor,
    hoverStaticFontSize: LIST_EXPANDED_STATIC_FONT_SIZE,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: deprecatedStyles.listContentTextFont,
    hoverColor: deprecatedStyles.listContentTextColor,
    hoverStaticFontSize: LIST_EXPANDED_STATIC_FONT_SIZE,
  },
  [WidgetText.LINK]: {
    font: deprecatedStyles.listContentTextFont,
    color: deprecatedStyles.listContentTextColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getSideBySideMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
  },
  [WidgetText.TITLE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
  },
  [WidgetText.DATE]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderTitleColor,
  },
  [WidgetText.LOCATION]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderTitleColor,
  },
  [WidgetText.DESCRIPTION]: {
    font: deprecatedStyles.cardDescriptionFont,
    color: deprecatedStyles.listHeaderTitleColor,
  },
  [WidgetText.LINK]: {
    font: deprecatedStyles.listHeaderLocationFont,
    color: deprecatedStyles.listHeaderTitleColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getCompactMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
    size: deprecatedStyles.listGeneralTitleFontSizeMobile,
  },
  [WidgetText.TITLE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    size: deprecatedStyles.listHeaderTitleFontSizeCompactMobile,
  },
  [WidgetText.DATE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    size: deprecatedStyles.listDateFontSizeCompactMobile,
  },
  [WidgetText.LOCATION]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    size: deprecatedStyles.listLocationFontSizeCompactMobile,
  },
  [WidgetText.DESCRIPTION]: {},
  [WidgetText.LINK]: {},
})

const getFullMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
    size: deprecatedStyles.listGeneralTitleFontSizeMobile,
  },
  [WidgetText.TITLE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    size: deprecatedStyles.listHeaderTitleFontSizeMobile,
  },
  [WidgetText.DATE]: {
    font: deprecatedStyles.listHeaderDateTimeFont,
    color: deprecatedStyles.listHeaderDateTimeColor,
    size: deprecatedStyles.listDateFontSizeMobile,
    hoverFont: deprecatedStyles.listContentTextFont,
    hoverColor: deprecatedStyles.listContentTextColor,
    hoverSize: deprecatedStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LOCATION]: {
    hoverFont: deprecatedStyles.listContentTextFont,
    hoverColor: deprecatedStyles.listContentTextColor,
    hoverSize: deprecatedStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: deprecatedStyles.listContentTextFont,
    hoverColor: deprecatedStyles.listContentTextColor,
    hoverSize: deprecatedStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LINK]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listContentTextColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

const getGridMap = (deprecatedStyles: GeneratedDeprecatedStyles): WidgetFontMap => ({
  [WidgetText.LIST_TITLE]: {
    font: deprecatedStyles.listGeneralTitleFont,
    size: deprecatedStyles.listGeneralTitleFontSizeMobile,
  },
  [WidgetText.TITLE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    size: deprecatedStyles.listHeaderTitleFontSizeMobile,
  },
  [WidgetText.DATE]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    size: deprecatedStyles.listDateFontSizeMobile,
    hoverFont: deprecatedStyles.listHeaderLocationFont,
    hoverColor: deprecatedStyles.listHeaderTitleColor,
    hoverSize: deprecatedStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LOCATION]: {
    hoverFont: deprecatedStyles.listHeaderLocationFont,
    hoverColor: deprecatedStyles.listHeaderTitleColor,
    hoverSize: deprecatedStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.DESCRIPTION]: {
    hoverFont: deprecatedStyles.listHeaderLocationFont,
    hoverColor: deprecatedStyles.listHeaderTitleColor,
    hoverSize: deprecatedStyles.listContentTextFontSizeMobile,
  },
  [WidgetText.LINK]: {
    font: deprecatedStyles.listHeaderTitleFont,
    color: deprecatedStyles.listHeaderTitleColor,
    staticFontSize: LINK_STATIC_FONT_SIZE,
    style: {underline: true},
  },
})

export const getV2ParamsMap = (deprecatedStyles: GeneratedDeprecatedStyles): StudioParamsMap => {
  const desktopGridMap = getCardsMap(deprecatedStyles)

  return {
    desktop: {
      [LIST_LAYOUT.NEW_GRID]: desktopGridMap,
      [LIST_LAYOUT.GRID]: desktopGridMap,
      [LIST_LAYOUT.LIST]: getListMap(deprecatedStyles),
      [LIST_LAYOUT.SIDE_BY_SIDE]: getSideBySideMap(deprecatedStyles),
      [LIST_LAYOUT.CALENDAR]: getCalendarMap(deprecatedStyles),
    },
    mobile: {
      [MOBILE_LIST_LAYOUT_TYPE.STRIPS]: getCompactMap(deprecatedStyles),
      [MOBILE_LIST_LAYOUT_TYPE.FULL]: getFullMap(deprecatedStyles),
      [ADDITIONAL_MOBILE_LIST_LAYOUT.GRID]: getGridMap(deprecatedStyles),
    },
  }
}
